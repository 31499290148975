import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, Video } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`In this article:`}</h4>
    <ul>
      <li parentName="ul">{`How to find trails in good conditions 🔎`}</li>
      <li parentName="ul">{`Preserve trails with little effort 💡`}</li>
      <li parentName="ul">{`Win 2 Norrøna fjøra products & 5 Trailguide jerseys 🏆`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://trailguide.no/news/trailserhalten/"
        }}>{`Artikel in Deutsch`}</a></li>
    </ul>
    <br />
    <br />
    <h4>{`❔ "Biking damages trails" - Is it true?`}</h4>
    <p>{`When the snow melts, or after rainfalls, trails can be wet, muddy and
in a bad shape. It's not much fun to ride on river-like trails, or to fight
through the deep mud.`}</p>
    <br />
    <p>{`In January 2021 a Norwegian `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
      }}>{`study`}</a>{`
was published that shows: `}<i>{`bikers and hikers have little impact on the wear
and tear of trails - as long as it's dry!`}</i>{` As soon as the soil gets wet it
leads to greater erosion.`}</p>
    <Image src="/news/_DSC5668.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <p>{`It's not only less fun to ride on a worn out trail. It can harm nature.
When the ground erodes, roots are exposed, and in the worst case even trees could be damaged.
Deep tire marks in the wet ground are well visible, the potential for conflicts with other interest groups increases.
So what can we do to prevent this?`}</p>
    <br />
    <br />
    <h4>{`💡 One possibility is to adapt the trails: mineral soil, better drainage.`}</h4>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
        }}>{`study`}</a>{`
says "...soils with high content of organic material or poor drainage..."
are reasons for problematic areas.`}</li>
      <li parentName="ul">{`Most trails in Europe are no purpose built MTB tracks. Sometimes drainages could be built
without changing the character of the trail. But by bringing in mineral soil
and creating a compacted surface, most of the trails would loose part of what makes
mountain biking interesting.`}</li>
      <li parentName="ul">{`Limited resources and legal issues make it impossible to enhance all existing trails.`}</li>
      <li parentName="ul">{`Trails are usually built and maintained by only a few people. But the following
option can be done by everyone:`}</li>
    </ul>
    <br />
    <br />
    <h4>{`💡 Ride dry trails, avoid wet conditions.`}</h4>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
        }}>{`study`}</a>{` shows
"Soil moisture was the most important environmental predictor for trail width increase,..."`}</li>
      <li parentName="ul">{`Up to date information is key to know where to go. While some trails quickly dry up,
others stay wet for a long time. Condition reports help a lot to make a good decision.`}</li>
      <li parentName="ul">{`Avoiding wet trails or sections not only helps to preserve them, it is also a lot
more fun to ride trails that are in good shape.`}</li>
      <li parentName="ul">{`Local trail building organizations also benefit from condition reports. They get
to know more about their trails, can react quickly to damages or hazards like a fallen
tree, broken obstacles, ...`}</li>
      <li parentName="ul">{`The potential for conflicts with other interest groups is reduced.`}</li>
      <li parentName="ul">{`Trails are "not just there". They have to be built and they have to be maintained.
Minimizing the impact on the trail can reduce this effort and the costs a lot.`}</li>
    </ul>
    <Image src="/news/DSC_9207.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`🔎 How to find trails in good conditions & report conditions`}</h4>
    <div className="flex justify-center">
  <Video className="w-full mx-auto my-12" src="cSPln27Zkh4" mdxType="Video" />
    </div>
    <ul>
      <li parentName="ul">{`On Trailguide you can easily see the condition reports of the trails.`}</li>
      <li parentName="ul">{`Filter for trails that are reported to be in good conditions.`}</li>
      <li parentName="ul">{`Leaving a condition report merely takes a couple of seconds.`}</li>
    </ul>
    <br />
    <br />
    <h4>{`🏆 Report conditions and win 2 Norrøna fjøra products & 5 Trailguide jerseys`}</h4>
    <p>{`To quote our sponsor `}<a parentName="p" {...{
        "href": "https://norrona.com"
      }}>{`Norrøna`}</a>{` "Without nature, there's no us" -
We would also say "Without trails there is no mountain biking". Reporting conditions
helps to preserve our trails and to enjoy mountain biking more. `}<br /><br />{` Norrøna
and Trailguide give away two amazing items of the fjøra mountain bike
collection and five Trailguide jerseys. `}<i>{`The prizes are drawn amongst everyone who reports conditions
on `}<a parentName="p" {...{
          "href": "https://trailguide.net"
        }}>{`trailguide.net`}</a>{` in June and July 2021.`}</i></p>
    <Image src="/news/norrona_fjora_2.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`📜 More info about the study:`}</h4>
    <p>{`Name: Effects of mountain biking versus hiking on trails under different environmental conditions`}</p>
    <p>{`Authors: Marianne Evju, Dagmar Hagen, Mari Jokerud, Siri Lie Olsen, Sofie Kjendlie Selvaag, Odd Inge Vistad`}</p>
    <p>{`Published in: Journal of Environmental Management,
Volume 278, Part 2,
2021,
111554,
ISSN 0301-4797`}</p>
    <p>{`Link: `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
      }}>{`https://www.sciencedirect.com/science/article/pii/S0301479720314791`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      